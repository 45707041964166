exports.components = {
  "component---src-pages-202310-interoperabilitya-js": () => import("./../../../src/pages/202310interoperabilitya.js" /* webpackChunkName: "component---src-pages-202310-interoperabilitya-js" */),
  "component---src-pages-202311-a-ifa-js": () => import("./../../../src/pages/202311AIfa.js" /* webpackChunkName: "component---src-pages-202311-a-ifa-js" */),
  "component---src-pages-202311-a-ifb-js": () => import("./../../../src/pages/202311AIfb.js" /* webpackChunkName: "component---src-pages-202311-a-ifb-js" */),
  "component---src-pages-202311-acc-ba-js": () => import("./../../../src/pages/202311AccBa.js" /* webpackChunkName: "component---src-pages-202311-acc-ba-js" */),
  "component---src-pages-202311-acc-bb-js": () => import("./../../../src/pages/202311AccBb.js" /* webpackChunkName: "component---src-pages-202311-acc-bb-js" */),
  "component---src-pages-202311-acc-rb-js": () => import("./../../../src/pages/202311AccRb.js" /* webpackChunkName: "component---src-pages-202311-acc-rb-js" */),
  "component---src-pages-202311-acc-s-ba-js": () => import("./../../../src/pages/202311AccSBa.js" /* webpackChunkName: "component---src-pages-202311-acc-s-ba-js" */),
  "component---src-pages-202311-acc-s-bb-js": () => import("./../../../src/pages/202311AccSBb.js" /* webpackChunkName: "component---src-pages-202311-acc-s-bb-js" */),
  "component---src-pages-202311-ai-tala-js": () => import("./../../../src/pages/202311AITala.js" /* webpackChunkName: "component---src-pages-202311-ai-tala-js" */),
  "component---src-pages-202311-ai-topfa-js": () => import("./../../../src/pages/202311AITopfa.js" /* webpackChunkName: "component---src-pages-202311-ai-topfa-js" */),
  "component---src-pages-202311-bana-js": () => import("./../../../src/pages/202311Bana.js" /* webpackChunkName: "component---src-pages-202311-bana-js" */),
  "component---src-pages-202311-banb-js": () => import("./../../../src/pages/202311Banb.js" /* webpackChunkName: "component---src-pages-202311-banb-js" */),
  "component---src-pages-202311-banlima-js": () => import("./../../../src/pages/202311Banlima.js" /* webpackChunkName: "component---src-pages-202311-banlima-js" */),
  "component---src-pages-202311-banuxa-js": () => import("./../../../src/pages/202311Banuxa.js" /* webpackChunkName: "component---src-pages-202311-banuxa-js" */),
  "component---src-pages-202311-banuxb-js": () => import("./../../../src/pages/202311Banuxb.js" /* webpackChunkName: "component---src-pages-202311-banuxb-js" */),
  "component---src-pages-202311-custa-js": () => import("./../../../src/pages/202311Custa.js" /* webpackChunkName: "component---src-pages-202311-custa-js" */),
  "component---src-pages-202311-custb-js": () => import("./../../../src/pages/202311Custb.js" /* webpackChunkName: "component---src-pages-202311-custb-js" */),
  "component---src-pages-202311-hc-compa-js": () => import("./../../../src/pages/202311HCCompa.js" /* webpackChunkName: "component---src-pages-202311-hc-compa-js" */),
  "component---src-pages-202311-hc-compb-js": () => import("./../../../src/pages/202311HCCompb.js" /* webpackChunkName: "component---src-pages-202311-hc-compb-js" */),
  "component---src-pages-202311-hc-mobb-js": () => import("./../../../src/pages/202311HCMobb.js" /* webpackChunkName: "component---src-pages-202311-hc-mobb-js" */),
  "component---src-pages-202311-hca-ia-js": () => import("./../../../src/pages/202311HCAIa.js" /* webpackChunkName: "component---src-pages-202311-hca-ia-js" */),
  "component---src-pages-202311-hca-ib-js": () => import("./../../../src/pages/202311HCAIb.js" /* webpackChunkName: "component---src-pages-202311-hca-ib-js" */),
  "component---src-pages-202311-intb-js": () => import("./../../../src/pages/202311Intb.js" /* webpackChunkName: "component---src-pages-202311-intb-js" */),
  "component---src-pages-202311-objb-js": () => import("./../../../src/pages/202311Objb.js" /* webpackChunkName: "component---src-pages-202311-objb-js" */),
  "component---src-pages-202311-talb-js": () => import("./../../../src/pages/202311Talb.js" /* webpackChunkName: "component---src-pages-202311-talb-js" */),
  "component---src-pages-202311-topfb-js": () => import("./../../../src/pages/202311Topfb.js" /* webpackChunkName: "component---src-pages-202311-topfb-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bhakti-js": () => import("./../../../src/pages/bhakti.js" /* webpackChunkName: "component---src-pages-bhakti-js" */),
  "component---src-pages-career-details-flutter-developer-js": () => import("./../../../src/pages/career-details/flutter-developer.js" /* webpackChunkName: "component---src-pages-career-details-flutter-developer-js" */),
  "component---src-pages-career-details-mean-mern-stack-developer-js": () => import("./../../../src/pages/career-details/mean-mern-stack-developer.js" /* webpackChunkName: "component---src-pages-career-details-mean-mern-stack-developer-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-clutch-landing-js": () => import("./../../../src/pages/clutch-landing.js" /* webpackChunkName: "component---src-pages-clutch-landing-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-flutter-app-development-js": () => import("./../../../src/pages/flutter-app-development.js" /* webpackChunkName: "component---src-pages-flutter-app-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keerthish-js": () => import("./../../../src/pages/keerthish.js" /* webpackChunkName: "component---src-pages-keerthish-js" */),
  "component---src-pages-krutik-js": () => import("./../../../src/pages/krutik.js" /* webpackChunkName: "component---src-pages-krutik-js" */),
  "component---src-pages-manasi-js": () => import("./../../../src/pages/manasi.js" /* webpackChunkName: "component---src-pages-manasi-js" */),
  "component---src-pages-nageswari-js": () => import("./../../../src/pages/nageswari.js" /* webpackChunkName: "component---src-pages-nageswari-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-requestuxreport-js": () => import("./../../../src/pages/requestuxreport.js" /* webpackChunkName: "component---src-pages-requestuxreport-js" */),
  "component---src-pages-sarthak-js": () => import("./../../../src/pages/sarthak.js" /* webpackChunkName: "component---src-pages-sarthak-js" */),
  "component---src-pages-sarvottam-js": () => import("./../../../src/pages/sarvottam.js" /* webpackChunkName: "component---src-pages-sarvottam-js" */),
  "component---src-pages-services-accessibility-audit-js": () => import("./../../../src/pages/services/accessibility-audit.js" /* webpackChunkName: "component---src-pages-services-accessibility-audit-js" */),
  "component---src-pages-services-ai-development-js": () => import("./../../../src/pages/services/ai-development.js" /* webpackChunkName: "component---src-pages-services-ai-development-js" */),
  "component---src-pages-services-android-js": () => import("./../../../src/pages/services/android.js" /* webpackChunkName: "component---src-pages-services-android-js" */),
  "component---src-pages-services-angular-js": () => import("./../../../src/pages/services/angular.js" /* webpackChunkName: "component---src-pages-services-angular-js" */),
  "component---src-pages-services-design-system-js": () => import("./../../../src/pages/services/design-system.js" /* webpackChunkName: "component---src-pages-services-design-system-js" */),
  "component---src-pages-services-flutter-js": () => import("./../../../src/pages/services/flutter.js" /* webpackChunkName: "component---src-pages-services-flutter-js" */),
  "component---src-pages-services-ios-js": () => import("./../../../src/pages/services/ios.js" /* webpackChunkName: "component---src-pages-services-ios-js" */),
  "component---src-pages-services-node-js": () => import("./../../../src/pages/services/node.js" /* webpackChunkName: "component---src-pages-services-node-js" */),
  "component---src-pages-services-python-js": () => import("./../../../src/pages/services/python.js" /* webpackChunkName: "component---src-pages-services-python-js" */),
  "component---src-pages-services-react-js": () => import("./../../../src/pages/services/react.js" /* webpackChunkName: "component---src-pages-services-react-js" */),
  "component---src-pages-services-ui-design-js": () => import("./../../../src/pages/services/ui-design.js" /* webpackChunkName: "component---src-pages-services-ui-design-js" */),
  "component---src-pages-services-ux-audit-js": () => import("./../../../src/pages/services/ux-audit.js" /* webpackChunkName: "component---src-pages-services-ux-audit-js" */),
  "component---src-pages-services-ux-design-js": () => import("./../../../src/pages/services/ux-design.js" /* webpackChunkName: "component---src-pages-services-ux-design-js" */),
  "component---src-pages-services-ux-research-js": () => import("./../../../src/pages/services/ux-research.js" /* webpackChunkName: "component---src-pages-services-ux-research-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/termsOfUse.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-ux-services-js": () => import("./../../../src/pages/ux-services.js" /* webpackChunkName: "component---src-pages-ux-services-js" */),
  "component---src-pages-voucher-generator-generate-js": () => import("./../../../src/pages/voucher-generator/generate.js" /* webpackChunkName: "component---src-pages-voucher-generator-generate-js" */),
  "component---src-pages-voucher-generator-index-js": () => import("./../../../src/pages/voucher-generator/index.js" /* webpackChunkName: "component---src-pages-voucher-generator-index-js" */),
  "component---src-pages-work-asent-js": () => import("./../../../src/pages/work/asent.js" /* webpackChunkName: "component---src-pages-work-asent-js" */),
  "component---src-pages-work-cubii-js": () => import("./../../../src/pages/work/cubii.js" /* webpackChunkName: "component---src-pages-work-cubii-js" */),
  "component---src-pages-work-hirevalley-js": () => import("./../../../src/pages/work/hirevalley.js" /* webpackChunkName: "component---src-pages-work-hirevalley-js" */),
  "component---src-pages-work-idhub-js": () => import("./../../../src/pages/work/idhub.js" /* webpackChunkName: "component---src-pages-work-idhub-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mybyk-js": () => import("./../../../src/pages/work/mybyk.js" /* webpackChunkName: "component---src-pages-work-mybyk-js" */),
  "component---src-pages-work-pedagogy-js": () => import("./../../../src/pages/work/pedagogy.js" /* webpackChunkName: "component---src-pages-work-pedagogy-js" */),
  "component---src-pages-work-ray-js": () => import("./../../../src/pages/work/ray.js" /* webpackChunkName: "component---src-pages-work-ray-js" */),
  "component---src-pages-work-terrace-ag-js": () => import("./../../../src/pages/work/terrace-ag.js" /* webpackChunkName: "component---src-pages-work-terrace-ag-js" */),
  "component---src-pages-work-tikler-js": () => import("./../../../src/pages/work/tikler.js" /* webpackChunkName: "component---src-pages-work-tikler-js" */),
  "component---src-pages-workwithus-js": () => import("./../../../src/pages/workwithus.js" /* webpackChunkName: "component---src-pages-workwithus-js" */)
}

